<template>
  <div>
    <v-progress-linear
      v-if="loading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear>
    <transition name="dashboard">
      <div v-if="!loading" class="main-div">
        <!-- <h1>Data BMT</h1> -->
        <div class="search">
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllCompany()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
            @keypress="getAllCompany()"
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="company"
          :loading="loading"
          :search="table.search"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          item-key="id"
          hide-default-footer
          class="elevation-0 pt-5"
          height="500px"
          dense
        >
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="green">
              mdi-check-outline
            </v-icon>
            <v-icon v-else color="red">
              mdi-close-outline
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="onClickFooter(0, item.id)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Ubah data BMT</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <div class="footer">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllSubscription()"
            />
          </div>
          <v-spacer></v-spacer>

          <template>
            <v-dialog scrollable v-model="dialog" max-width="1400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on" @click="onClickFooter(0, '')">
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <company
                :id_company="selectedCompany"
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'
import Company from './components/Company'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  components: {
    Company
  },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      isAdmin: false,
      company: [],
      companyNewStatus: false,
      selectedCompany: '',
      dialog: '',
      selectedForm: null,
      pages: 0,
      table: {
        search: '',
        headers: [
          { text: 'Nama', value: 'name', width: '120px' },
          { text: 'Jenis', value: 'type', width: '80px', sortable: false },
          { text: 'Alamat', value: 'address', width: '200px', sortable: false },
          { text: 'Email', value: 'email', width: '50px', sortable: false },
          { text: 'Telepon', value: 'phone', width: '50px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      }
    }
  },
  watch: {
    company: {
      handler() {
        this.$nextTick(() => {
          this.table.pagination.totalItems = this.company.length
          if (this.table.pagination.itemsPerPage == null || this.table.pagination.totalItems == null) {
            this.pages = 0
          } else {
            this.pages = Math.ceil(this.table.pagination.totalItems / this.table.pagination.itemsPerPage)
          }
        })
      },
      deep: true
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllCompany()
      if (this.$store.getters.userLoggedIn.role.role === 'administrator') this.isAdmin = true
    })
  },
  methods: {
    getAllCompany(option) {
      let sortBy = _.get(option, 'sortDesc[0]', false) ? '-name' : 'name'

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/company?page=',
              _.get(option, 'page', this.table.pagination.page),
              '&itemCount=',
              _.get(option, 'itemsPerPage', this.table.pagination.itemsPerPage),
              '&sort=',
              sortBy,
              '&search=',
              this.table.search,
              '&all=1'
            )
          )
          .then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.company = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR')
                .map(company => {
                  return {
                    id: _.get(company, '_id', '').toString(),
                    type: _.get(company, 'isHeadquarters', '') ? 'Pusat' : 'Cabang',
                    address: _.get(company, 'address.description', ''),
                    name: _.get(company, 'name', ''),
                    phone: _.get(company, 'phone', ''),
                    email: _.get(company, 'email', '')
                  }
                })
            }
            this.loading = false
          })
      })
    },
    deleteCompany(items) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: 'Data BMT akan dihapus',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          if (items.status === true) {
            this.companyNewStatus = false
          } else {
            this.companyNewStatus = true
          }
          axios
            .post(Constant.apiUrl.concat('/company/data/status/', items.id), {
              status: this.companyNewStatus
            })
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Status Company Berhasil Diubah', 'success')
                this.getAllCompany()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      })
    },
    onClickFooter(index, id_company) {
      this.selectedCompany = id_company
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },
    togglePropsExpanded(props) {
      this.$set(props, 'expanded', !props.expanded)
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllCompany()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}
.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
