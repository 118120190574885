<template>
  <v-card>
    <v-progress-linear
      v-if="!alert"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="6" style="max-height: 400px; overflow-y: auto;">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="member">
                      <h2>
                        <b>Peserta</b>
                      </h2>
                    </label>
                  </div>
                  <v-combobox
                    v-if="!id_subscription"
                    :rules="[rules.required]"
                    id="member"
                    v-model="selectedMember"
                    dense
                    placeholder="Pilih Peserta"
                    class="detail-input__input-text  mr-5"
                    :items="resource.member"
                    :loading="loadingMember"
                    item-text="name"
                    :search-input.sync="searchMember"
                    return-object
                    :disabled="isAdmin"
                    :clearable="!id_subscription"
                    no-data-text="Tidak ditemukan"
                  ></v-combobox>
                  <v-text-field
                    v-else
                    v-model="selectedMember.name"
                    class="detail-input__input-text mr-5"
                    readonly
                  >
                  </v-text-field>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="address">Alamat</label>
                  </div>
                  <v-textarea
                    id="address"
                    v-model="selectedMember.fullAddress"
                    readonly
                    class="detail-input__input-text align-start  mr-5"
                    height="50"
                    outlined
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="nik">NIK</label>
                  </div>
                  <v-text-field
                    id="nik"
                    readonly
                    v-model="selectedMember.nik"
                    dense
                    class="detail-input__input-text mr-5"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="birthDate"
                      >Tanggal Lahir
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="blink" v-bind="attrs" v-on="on">mdi-information</v-icon>
                        </template>
                        <span>Batas Usia maksimal 70 Tahun 5 Bulan, batas minimal 18 Tahun</span>
                      </v-tooltip>
                    </label>
                  </div>
                  <v-text-field
                    id="birthDate"
                    readonly
                    v-model="selectedMember.birth_date"
                    dense
                    class="detail-input__input-text mr-5"
                  />
                </div>

                <div class="detail-input" v-if="selectedProduct.type.includes('kebakaran')">
                  <div class="detail-input__label">
                    <label for="birthDate">
                      NPWP BMT Pengaju
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="blink" v-bind="attrs" v-on="on">mdi-information</v-icon>
                        </template>
                        <span>Khusus Produk Kebakaran</span>
                      </v-tooltip>
                    </label>
                  </div>
                  <v-text-field
                    id="npwp"
                    readonly
                    v-model="selectedCompany.npwp"
                    dense
                    class="detail-input__input-text mr-5"
                  />
                </div>
                <br />
              </v-col>
              <!-- <v-col md="2"></v-col> -->
              <v-divider vertical></v-divider>
              <v-col md="6">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="product">
                      <h2>
                        <b>Produk</b>
                      </h2>
                    </label>
                  </div>
                  <!-- v-if="!id_subscription" -->
                  <v-combobox
                    :rules="[rules.required]"
                    id="product"
                    v-model="selectedProduct"
                    dense
                    placeholder="Pilih Produk"
                    class="detail-input__input-text mr-5"
                    :items="resource.product"
                    :loading="loadingProduct"
                    item-text="name"
                    @blur="calculateSubscription()"
                    return-object
                    :disabled="isAdmin"
                    ><template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.code }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.code }} - {{ data.item.name }}
                    </template>
                  </v-combobox>
                  <!-- <v-text-field
                    v-else
                    v-model="selectedProduct.code"
                    class="detail-input__input-text mr-5"
                    readonly
                  ></v-text-field> -->
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="productName">Nama</label>
                  </div>
                  <v-textarea
                    id="productName"
                    v-model="selectedProduct.name"
                    readonly
                    class="detail-input__input-text align-start  mr-5"
                    height="100"
                    outlined
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="productType">Jenis</label>
                  </div>
                  <v-text-field
                    id="productType"
                    readonly
                    v-model="selectedProduct.type"
                    dense
                    class="detail-input__input-text mr-5"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="dueMax">Batas Maksimal</label>
                  </div>
                  <v-text-field
                    id="dueMax"
                    readonly
                    v-model="selectedProduct.due_max"
                    dense
                    suffix="Bulan"
                    class="detail-input__input-text mr-5"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="plafond">Batas Plafond</label>
                  </div>
                  <v-currency-field
                    id="plafond"
                    readonly
                    v-model="selectedProduct.plafond_max"
                    dense
                    prefix="Rp."
                    class="detail-input__input-text mr-5"
                  />
                </div>
              </v-col>
            </v-row>
            <v-col>
              <div>
                <v-alert
                  class="d-flex justify-center"
                  dense
                  transition="scale-transition"
                  :value="alert"
                  :type="alertType"
                >
                  {{ this.alertTitle }}
                  <br v-if="this.alertTitle != ''" />
                  <div class="d-flex justify-center">{{ this.alertMessage }}</div>
                </v-alert>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-row>
              <v-col md="5">
                <br />
                <div class="detail-input" v-if="selectedProduct.type.includes('kebakaran')">
                  <div class="detail-input__label">
                    <label for="religion"
                      >Objek Usaha
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="blink" v-bind="attrs" v-on="on">mdi-information</v-icon>
                        </template>
                        <span>Khusus Produk Kebakaran</span>
                      </v-tooltip>
                    </label>
                  </div>
                  <v-text-field
                    :readonly="isAdmin"
                    id="bussinesInsurance"
                    v-model="input.bussines.insurance"
                    dense
                    required
                    :loading="loading"
                    placeholder="Pilih Objek Pertanggungan"
                    class="detail-input__input-text"
                    @blur="alertMsg()"
                  ></v-text-field>
                </div>
                <div class="detail-input" v-if="selectedProduct.type.includes('kebakaran')">
                  <div class="detail-input__label">
                    <label for="businessAddress"
                      >Alamat Lengkap Usaha
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="blink" v-bind="attrs" v-on="on">mdi-information</v-icon>
                        </template>
                        <span>Khusus Produk Kebakaran</span>
                      </v-tooltip>
                    </label>
                  </div>
                  <v-text-field
                    :readonly="isAdmin"
                    id="businessAddress"
                    v-model="input.bussines.address"
                    dense
                    placeholder="Masukkan Alamat Usaha"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                    @blur="alertMsg()"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="iplafond">Plafond</label>
                  </div>
                  <v-currency-field
                    :readonly="isAdmin"
                    :rules="[rules.required]"
                    @keypress="onlyNumber"
                    id="iplafond"
                    v-model="input.plafond"
                    prefix="Rp."
                    dense
                    placeholder="Masukkan Pembiayaan"
                    class="detail-input__input-text"
                    :loading="loading"
                    @blur="
                      alertMsg()
                      calculateSubscription()
                    "
                  />
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="periode">Jangka Waktu</label>
                  </div>
                  <v-text-field
                    :readonly="isAdmin"
                    :rules="[rules.required]"
                    @keypress="onlyNumber"
                    id="periode"
                    v-model="input.periode"
                    suffix="Bulan"
                    dense
                    placeholder="Masukkan Jangka Waktu"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                    @blur="
                      alertMsg()
                      calculateSubscription()
                    "
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="disbursement">Tanggal Pencairan</label>
                  </div>

                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="input.disbursement"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    :disabled="isAdmin"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[rules.required]"
                        id="disbursement"
                        dense
                        placeholder="Masukkan Tanggal Pencairan"
                        class="detail-input__input-text"
                        required
                        readonly
                        :clearable="!isAdmin"
                        :loading="loading"
                        v-bind="attrs"
                        v-on="on"
                        :value="disbursementFormattedMomentjs"
                        @click:clear="input.disbursement = ''"
                        @blur="calculateSubscription()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @click:date="
                        $refs.menu.save(input.disbursement)
                        menu = false
                        calculateSubscription()
                        alertMsg()
                      "
                      v-model="input.disbursement"
                      locale="id-ID"
                      no-title
                      scrollable
                      :disabled="isAdmin"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col md="2">
                <div class="label-header">
                  <label>
                    <h3>
                      <b>Pembiayaan</b>
                    </h3>
                  </label>
                </div>
              </v-col>
              <v-col md="5">
                <br />

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="tariffPercent">Tarif Iuran</label>
                  </div>
                  <v-text-field
                    id="tariffPercent"
                    readonly
                    v-model="input.tariff.percent"
                    dense
                    suffix="%"
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="tariffNominal">Jumlah Iuran</label>
                  </div>
                  <v-currency-field
                    id="tariffNominal"
                    readonly
                    v-model="input.tariff.nominal"
                    dense
                    prefix="Rp."
                    class="detail-input__input-text"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="dueDate">Jatuh Tempo</label>
                  </div>
                  <v-text-field
                    id="dueDate"
                    readonly
                    v-model="dueFormattedMomentjs"
                    dense
                    class="detail-input__input-text"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :disabled="isSaveDisable" color="secondary" width="100" text large @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :loading="loadingSave"
            v-if="!isAdmin && isSave"
            v-bind="attrs"
            v-on="on"
            :color="!save ? 'red' : 'primary'"
            width="100"
            @click="saveSubscription()"
            large
          >
            Simpan
          </v-btn>
        </template>
        <span>{{ !save ? 'Isi data dengan lengkap!' : 'Tercover' }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  mixins: [ConstantMixin],
  props: {
    id_subscription: String,
    id_company: String,
    id_member: String,
    isSave: Boolean
  },
  data: () => ({
    title: 'Daftar Iuran Peserta',
    loading: false,
    loadingMember: false,
    loadingProduct: false,
    loadingSave: false,
    dialog: false,
    dialogDelete: false,
    activeRadio: '',
    alert: false,
    alertType: 'success',
    alertMessage: 'ok',
    alertTitle: '',
    isNew: true,
    isAdmin: false,
    menu: false,
    save: false,
    isSaveDisable: false,
    input: {
      id_company: '',
      id_member: '',
      id_product: '',
      isDemo: false,
      plafond: '',
      disbursement: '',
      periode: '',
      due_date: '',
      tariff: {
        percent: 0,
        nominal: 0
      },
      paidStatus: [],
      claimStatus: [],
      timestamps: {
        created_by: ''
      },
      bussines: {
        address: '',
        insurance: ''
      }
    },
    rules: {
      required: value => !!value || 'Harus di isi!',
      plafond: () => {}
    },
    selectedMember: '',
    selectedProduct: {
      name: '',
      type: ''
    },
    selectedCompany: '',
    searchMember: '',
    resource: {
      member: [],
      product: [],
      bussinesInsurance: []
    }
  }),
  created() {},
  computed: {
    disbursementFormattedMomentjs() {
      return this.input.disbursement
        ? moment(this.input.disbursement)
            .locale('id')
            .format('D MMMM YYYY')
        : ''
    },
    dueFormattedMomentjs() {
      return this.input.due_date
        ? moment(this.input.due_date)
            .locale('id')
            .format('D MMMM YYYY')
        : ''
    }
  },
  watch: {
    // 'resource.member': function(val) {
    //
    //   if (this.id_subscription) this.getSubscription()
    // },
    // 'resource.product': function(val) {
    //
    //   if (this.id_subscription) this.getSubscription()
    // },
    selectedMember: function(val) {
      if (!val) this.selectedMember = ''
      else this.input.id_member = this.selectedMember._id
      this.alertMsg()
    },
    selectedProduct: function() {
      this.input.id_product = this.selectedProduct._id
      this.alertMsg()
    },
    searchMember(val) {
      if (val) this.searchData(this)
      else this.resource.member = []
    }
  },
  async mounted() {
    if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') this.isAdmin = true
    // this.getMember()
    if (this.id_subscription != '') {
      this.title = 'Ubah Iuran Peserta'
      this.isNew = false
      this.getSubscription()
    } else {
      this.input.id_company = this.$store.getters.userLoggedIn.company.id
    }
    this.getProduct()
    this.input.timestamps.created_by = this.$store.getters.userLoggedIn.id
    this.getCompany()
    this.alertMsg()
    this.input.isDemo = this.isDemo
  },
  methods: {
    searchData: _.debounce(v => {
      v.getMember()
    }, 650),
    setAlert(alert, type, msg, title) {
      this.alertType = type
      this.alert = alert
      this.alertMessage = msg
      this.alertTitle = title || ''
    },
    alertMsg() {
      if (this.isSave) {
        this.save = false
        this.setAlert(false, 'success', '')
        if (!this.selectedMember || !this.selectedProduct) {
          if (!this.selectedMember) this.setAlert(true, 'info', 'Pilih Peserta')
          if (!this.selectedProduct) this.setAlert(true, 'info', 'Pilih Produk')
          if (!this.selectedMember && !this.selectedProduct)
            this.setAlert(true, 'info', 'Pilih Peserta dan Produk')
        } else {
          if (this.selectedMember.age >= 70)
            if (this.selectedMember.age == 70 && this.selectedMember.ageMonth < 6) {
            } else {
              return this.setAlert(true, 'error', 'Peserta sudah berusia lebih dari 70 tahun 5 bulan')
            }

          if (this.selectedMember.age < 18)
            return this.setAlert(true, 'error', 'Peserta belum berusia 18 tahun')

          if (this.selectedProduct.type.includes('kebakaran')) {
            let message = []
            // if (!this.selectedCompany.npwp) message.push('NPWP BMT')

            if (message.length > 0) {
              return this.setAlert(
                true,
                'error',
                `Data belum tersedia: ${message.join(', ')}. Silahkan lengkapi data Peserta terlebih dahulu.`
              )
            }
          }

          const message = []
          if (
            !this.input.periode ||
            !this.input.plafond ||
            !this.input.disbursement ||
            (this.selectedProduct.type.includes('kebakaran') && !this.input.bussines.insurance) ||
            (this.selectedProduct.type.includes('kebakaran') && !this.input.bussines.address)
          ) {
            if (!this.input.periode) message.push('Jangka Waktu')
            if (!this.input.plafond) message.push('Plafond')
            if (!this.input.disbursement) message.push('Tanggal Pencairan')

            if (this.selectedProduct.type.includes('kebakaran')) {
              if (!this.input.bussines.insurance) message.push('Objek Usaha')
              if (!this.input.bussines.address) message.push('Alamat Usaha')
            }

            this.setAlert(true, 'warning', `Silahkan Lengkapi data ${message.join(', ')}`)
          }

          let plafond = this.selectedMember.plafond

          let isPlafond =
            (this.id_subscription ? 0 : this.input.plafond) + plafond <= this.selectedProduct.plafond_max

          let isPeriode = this.input.periode <= this.selectedProduct.due_max && this.input.periode > 2

          let isInput = !!this.input.plafond && !!this.input.disbursement && !!this.input.periode
          let title = ''

          // if (this.selectedProduct.type.includes('kebakaran')) {
          //   if (!this.input.bussines.insurance) {
          //     this.setAlert(true, 'error', 'Objek Usaha harus diisi', title)
          //   }
          //   if (!this.input.bussines.address) {
          //     this.setAlert(true, 'error', 'Alamat Usaha harus diisi', title)
          //   }
          // }
          if (plafond > 0) {
            title = `Peserta memiliki plafond ${this.getCurrency(plafond)}`
            this.setAlert(true, 'warning', `Silahkan Lengkapi data ${message.join(', ')}`, title)
          }

          if (isPlafond && isPeriode && isInput) {
            if (this.selectedProduct.type.includes('kebakaran'))
              if (!this.input.bussines.insurance || !this.input.bussines.address) return
            this.save = true
            this.setAlert(true, 'success', 'Tercover!', title)
          }
          //  else if (!isPlafond && !isPeriode)
          //   this.setAlert(true, 'error', 'Plafond dan/atisPlafondau Periode Tidak Sesuai!', title)
          else if (this.selectedProduct.plafond_max && !isPlafond)
            this.setAlert(true, 'error', 'Plafond Melebihi Batas!', title)
          else if (!isPeriode && this.input.periode > 0) {
            if (this.input.periode > this.selectedProduct.due_max)
              this.setAlert(true, 'error', 'Jangka Waktu Melebihi Batas!', title)
            if (this.input.periode < 3) this.setAlert(true, 'error', 'Jangka Waktu Minimal 3 Bulan', title)
          }
        }
      } else {
        this.setAlert(true, 'success', 'Tercover!')
      }
    },

    calculateSubscription() {
      if (this.input.periode && this.input.plafond) {
        this.input.tariff.percent = this.selectedProduct.due_year
        this.input.tariff.nominal = Math.round(
          this.input.plafond * (this.input.periode / 100) * this.selectedProduct.due_year
        )
      }
      if (this.input.disbursement && this.input.periode) {
        this.input.due_date = moment(this.input.disbursement)
          .add(this.input.periode, 'month')
          .format()
      }
    },
    getCompany() {
      if (this?.input?.id_company) {
        axios.get(Constant.apiUrl.concat(`/master/company/${this.input.id_company}`)).then(response => {
          if (response.status === 200) {
            this.selectedCompany = response.data.data
          } else {
            this.selectedCompany = ''
          }
        })
      }
    },
    getMember(id_member) {
      if (!this.searchMember) {
        this.resource.member = []
        return
      }
      this.loadingMember = true
      let uri = Constant.apiUrl.concat(
        '/member?company=',
        this.id_company || this.$store.getters.userLoggedIn.company.id,
        '&search=',
        this.searchMember || ''
      )

      if (id_member) uri = Constant.apiUrl.concat('/member/', id_member)

      axios
        .get(uri)
        .then(response => {
          if (response.status === 200) {
            let resData = response.data.data
            if (id_member) resData = [resData]

            this.resource.member = resData.map(r => {
              let member = r
              let age = moment().diff(member.birth_date, 'year')
              const ageMonth = moment().diff(member.birth_date, 'months') % 12
              member.age = age
              member.fullAddress = r.address.description
              member.ageMonth = ageMonth
              member.birth_date = moment(member.birth_date)
                .locale('id')
                .format('D MMMM YYYY')
                .concat(` (${age} Tahun, ${ageMonth} Bulan)`)
              member.name = member.name.concat(` (${member.nik})`)
              return member
            })
            if (id_member) this.selectedMember = this.resource.member[0]
            this.loadingMember = false
          } else {
            this.resource.member = []
            this.loadingMember = false
          }
        })
        .catch(error => {
          this.resource.member = []
          this.loadingMember = false
          // this.swalToast('error', `${this.searchMember} tidak ditemukan`)
        })
    },
    getProduct() {
      this.loadingProduct = true
      axios.get(Constant.apiUrl.concat('/product/active?sort=code')).then(response => {
        if (response.status === 200) {
          const resData = response.data.data
          this.resource.product = resData.map(r => {
            return r
          })
          this.loadingProduct = false
        } else {
          this.resource.product = []
          this.loadingProduct = false
        }
      })
    },
    async getSubscription() {
      try {
        this.loading = true
        await axios.get(Constant.apiUrl.concat('/subscription/', this.id_subscription)).then(response => {
          const subscription = response.data.data
          this.input.id_company = subscription?.id_company
          this.input.id_member = subscription?.id_member
          this.input.id_product = subscription?.id_product
          this.input.plafond = subscription?.plafond
          this.input.disbursement = subscription?.disbursement
          this.input.due_date = subscription?.due_date
          this.input.periode = subscription?.periode
          this.input.bussines = {
            insurance: subscription?.bussines?.insurance,
            address: subscription?.bussines?.address
          }
          this.getCompany()
          this.selectedMember = {
            ...subscription?.member,
            fullAddress: subscription?.member?.address?.description,
            age: moment().diff(subscription?.member?.birth_date, 'year'),
            ageMonth: moment().diff(subscription?.member?.birth_date, 'months') % 12,
            birth_date: moment(subscription?.member?.birth_date)
              .locale('id')
              .format('D MMMM YYYY')
              .concat(
                ` (${moment().diff(subscription?.member?.birth_date, 'year')} Tahun, ${moment().diff(
                  subscription?.member?.birth_date,
                  'month'
                ) % 12} Bulan)`
              )
          }

          this.selectedProduct = subscription?.product

          // for (const member of this.resource.member) {
          //   if (this.input.id_member == member._id) {
          //     this.selectedMember = member
          //   }
          // }
          // for (const product of this.resource.product) {
          //   if (this.input.id_product == product._id) {
          //     this.selectedProduct = product
          //   }
          // }
          this.calculateSubscription()
          this.loading = false
        })
      } catch (error) {
        this.loading = false
      }
    },
    saveSubscription() {
      try {
        if (this.save && !this.isSaveDisable && !this.loadingSave) {
          this.loadingSave = true
          this.isSaveDisable = true
          this.input.disbursement = moment(this.input.disbursement).format()
          this.input.due_date = moment(this.input.due_date).format()
          this.swalLoading()
          if (!this.id_subscription) {
            this.runApiCalls(() => {
              axios
                .post(Constant.apiUrl.concat('/subscription/register'), this.input)
                .then(response => {
                  if (response.status === 200) {
                    this.$socket.client.emit('subscription:update', {
                      company: this.$store.getters.userLoggedIn.company.name,
                      staff: this.$store.getters.userLoggedIn
                    })
                    // Swal.fire('Berhasil', 'Data Iuran Peserta Berhasil Disimpan', 'success')
                    this.swalToast('success', 'Data Iuran Peserta Berhasil Disimpan')
                    this.onCloseButtonClick()
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
                .finally(() => {
                  this.loadingSave = false
                })
            })
          } else {
            this.runApiCalls(() => {
              axios
                .put(Constant.apiUrl.concat('/subscription/', this.id_subscription), this.input)
                .then(response => {
                  if (response.status === 200) {
                    this.$socket.client.emit('subscription:update', {
                      company: this.$store.getters.userLoggedIn.company.name,
                      staff: this.$store.getters.userLoggedIn
                    })
                    this.onCloseButtonClick()
                    // Swal.fire('Berhasil', 'Data Subscription Berhasil Perbarui', 'success')
                    this.swalToast('success', 'Data Subscription Berhasil Perbarui')
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
                .finally(() => {
                  this.loadingSave = false
                })
            })
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan subscription!',
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },

    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-header {
  color: #222;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}

input:placeholder-shown {
  font-size: 0.5em;
}
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
